import parse from 'html-react-parser'

import { Modal } from '.'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { Button } from '@/components/button'
import { useTranslation } from 'react-i18next'
import { useStore, useSession } from '@/util/store'
import { SubmitTestModalError } from './submit-test-modal-error'
import { useProperties } from '@/util/hooks/useProperties'
import { testIdentifiers } from '@/util/consts'

import { useSubmitTestData } from '@/util/hooks/useSubmitTestData'

export function TimeLimitModal() {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [submitTestData, submitError] = useSubmitTestData()

  const closeModal = useStore((s) => s.closeModal)
  const isOnline = useStore((state) => state.isOnline)
  const { t } = useTranslation(['tests', 'common'])
  const increaseSubTestIdx = useSession((s) => s.increaseSubTestIdx)
  const resetQuestionIdx = useSession((s) => s.resetQuestionIdx)
  const activeSubTest = useSession((s) => s.activeSubTest)
  const respondentToken = useSession((s) => s.respondentToken)
  const getTimeUsed = useSession((s) => s.getTimeUsed)
  const failedResponses = useSession((state) => state.failedResponses)
  const removeFailedResponse = useSession((state) => state.removeFailedResponse)
  const resetTimer = useSession((state) => state.resetTimer)
  const { testIdentifier } = useProperties()
  const isLegacy = testIdentifier === testIdentifiers.MAB2

  async function nextSubTest() {
    document.activeElement.blur()
    if (isSubmitting) return
    setIsSubmitting(true)

    const routeToNextSubTest = () => {
      increaseSubTestIdx()
      resetQuestionIdx()
      setIsSubmitting(false)
      resetTimer()
      const redirect = isLegacy
        ? `/tests/test/section/overview`
        : `/tests/active/`
      router.replace(redirect)
      closeModal()
    }

    try {
      const failedResponseArray = Object.keys(failedResponses)
      const failedResponsesSnapshot = { ...failedResponses }
      await submitTestData({
        activeSubTest,
        respondentToken,
        timeUsed: getTimeUsed(),
        unpostedResponses: failedResponses,
        onComplete: () => {
          failedResponseArray.map((response) => {
            removeFailedResponse(
              failedResponsesSnapshot[response].response.subtest_question_id,
              failedResponsesSnapshot[response].response.timestamp
            )
          })
          routeToNextSubTest()
        },
      })
    } catch (err) {
      setTimeout(() => setIsSubmitting(false), 2000)
    }
  }

  return (
    <Modal variant='medium body-no-close' hideClose>
      <div className='modal-header'>
        <h2 className='tc'>{parse(t('modals.endOfTestTimeLimit.heading'))}</h2>
      </div>
      <div className='modal-content'>
        <p className='tl'>
          {parse(
            t('modals.testEndTimeLimit.instructions', {
              buttonText: t('common:start').toUpperCase(),
            })
          )}
        </p>
        <SubmitTestModalError
          submitError={submitError}
          submitButtonLabel={t('common:start')}
        />
      </div>
      <div className='modal-actions'>
        <Button
          type='button'
          onClick={nextSubTest}
          isSubmitting={isSubmitting}
          disabled={!isOnline || isSubmitting}
          className='btn'
        >
          {t('common:start')}
        </Button>
      </div>
    </Modal>
  )
}
