export const apiUrl = process.env.NEXT_PUBLIC_API_URL
export const baseUrl = process.env.NEXT_PUBLIC_URL
export const defaultProfilerCode =
  process.env.NEXT_PUBLIC_DEFAULT_PROFILER_TEST_CODE

const settings = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  baseUrl: process.env.NEXT_PUBLIC_URL,
  defaultProfilerCode: process.env.NEXT_PUBLIC_DEFAULT_PROFILER_TEST_CODE,
}

export default settings
