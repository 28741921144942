// Minutes
export const TEST_TIMEOUT_TIME = 45
export const ADMIN_TIMEOUT_TIME = 15

export const reportStatuses = {
  notGenerated: 'not-generated',
  generated: 'generated',
  processing: 'processing',
  failed: 'failed',
  running: 'running',
  awaitingScoring: 'awaiting_scoring',
  waiting: 'waiting',
}

export const inProgressReportStatuses = [
  reportStatuses.processing,
  reportStatuses.waiting,
  reportStatuses.running,
  reportStatuses.awaitingScoring,
]

export const respondentStatuses = {
  notStarted: 'new',
  inProgress: 'inprogress',
  complete: 'complete',
}

export const reportTypes = {
  CSV: 'csv',
  PDF: 'pdf',
}

export const testIdentifiers = {
  // add entry to testDetails when introducing new identifiers
  NEOB: 'NEO_B',
  NEOC: 'NEO_C',
  MAB2: 'MAB_II',
  MAB3: 'MAB_III',
  MAB3_DEFAULT: 'MAB_III_DEFAULT',
  PAI: 'PAI',
  PRFR_OPT: 'PRFR_OPT', // SIGMA Personality
  SIGMA_DEVELOP: 'SIGMA_DEVELOP',
  SIGMA_SELECT: 'SIGMA_SELECT',
  PRFR_POLICE: 'PRFR_POLICE',
  SSPO: 'SSPO',
  SSPO_A: 'SSPO_A',
  SSPO_B: 'SSPO_B',
  SSPO_C: 'SSPO_C',
  SSPO_D: 'SSPO_D',
  SSPO_US_A: 'SSPO_US_A',
  SSPO_US_B: 'SSPO_US_B',
  SSPO_US_C: 'SSPO_US_C',
  SSPO_US_D: 'SSPO_US_D',
}

export const testProperties = {
  [testIdentifiers.MAB2]: {
    order: 0,
  },
  [testIdentifiers.MAB3]: {
    order: 1,
    pdfUnavailable: true,
  },
  [testIdentifiers.MAB3_DEFAULT]: {
    order: 1,
    pdfUnavailable: true,
  },
  [testIdentifiers.NEOB]: {
    order: 2,
    noOverview: true,
  },
  [testIdentifiers.NEOC]: {
    order: 3,
    noOverview: true,
  },
  [testIdentifiers.SSPO_A]: {
    order: 4,
  },
  [testIdentifiers.SSPO_B]: {
    order: 5,
  },
  [testIdentifiers.SSPO_C]: {
    order: 6,
  },
  [testIdentifiers.SSPO_D]: {
    order: 7,
  },
  [testIdentifiers.SSPO_US_A]: {
    order: 8,
  },
  [testIdentifiers.SSPO_US_B]: {
    order: 9,
  },
  [testIdentifiers.SSPO_US_C]: {
    order: 10,
  },
  [testIdentifiers.SSPO_US_D]: {
    order: 11,
  },
  [testIdentifiers.PRFR_POLICE]: {
    order: 12,
    noOverview: true,
  },
  [testIdentifiers.PRFR_OPT]: {
    // SIGMA Personality
    order: 13,
    noOverview: true,
    noAbbreviation: true,
  },
  [testIdentifiers.SIGMA_DEVELOP]: {
    order: 14,
    noOverview: true,
    noAbbreviation: true,
  },
  [testIdentifiers.SIGMA_SELECT]: {
    order: 15,
    noOverview: true,
    noAbbreviation: true,
  },
}

export const assessmentStatuses = {
  notStarted: 'new',
  inProgress: 'inprogress',
  complete: 'complete',
}

export const reportGeneratedStatuses = {
  complete: 'completed',
  failed: 'failed',
  inProgress: 'running',
  notStarted: 'waiting',
}

export const subtestTypes = {
  arithmetic: 'arithmetic',
  cancellation: 'cancellation',
  comprehension: 'comprehension',
  d: 'd',
  digit_span_backward: 'digit_span_backward',
  digit_span_forward: 'digit_span_forward',
  digit_span_sequence: 'digit_span_sequence',
  digit_symbol_coding: 'digit_symbol_coding',
  digits: 'digits',
  figure_weights: 'figure_weights',
  hss: 'hss',
  information: 'information',
  letter_span_sequence: 'letter_span_sequence',
  matrix_reasoning: 'matrix_reasoning',
  neo: 'neo',
  number_letter_span_sequence: 'number_letter_span_sequence',
  object_assembly: 'object_assembly',
  picture_arrangement: 'picture_arrangement',
  picture_completion: 'picture_completion',
  prfr: 'prfr',
  scales: 'scales',
  similarities: 'similarities',
  spatial: 'spatial',
  symbol_search: 'symbol_search',
  visual_puzzle: 'visual_puzzle',
  vocabulary: 'vocabulary',
  scenario: 'scenario',
}

export const MAB3Sections = {
  verbalComprehension: [
    subtestTypes.information,
    subtestTypes.similarities,
    subtestTypes.vocabulary,
    subtestTypes.arithmetic,
    subtestTypes.comprehension,
  ],
  perceptualReasoning: [
    subtestTypes.spatial,
    subtestTypes.matrix_reasoning,
    subtestTypes.visual_puzzle,
    subtestTypes.object_assembly,
    subtestTypes.figure_weights,
    subtestTypes.picture_completion,
    subtestTypes.picture_arrangement,
  ],
  workingMemory: [
    subtestTypes.digit_span_forward,
    subtestTypes.digit_span_backward,
    subtestTypes.digit_span_sequence,
    subtestTypes.number_letter_span_sequence,
  ],
  processingSpeed: [
    subtestTypes.symbol_search,
    subtestTypes.digit_symbol_coding,
    subtestTypes.cancellation,
  ],
}

export const SURVEY_FLAGS = {
  // BE
  demographics: 'shouldAdministerDemographicsSurvey',

  // FE only
  demographicsDeclined: 'demographicsDeclined',
}

export { TEAM_ROLES } from './team-roles'
