import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { Modal } from '.'
import { Button } from '@/components/button'
import { useStore, useSession } from '@/util/store'
import { useBookmarking } from '@/util/hooks'
import parse from 'html-react-parser'
import { showNotification } from '../notification'
import { isAnInstructionsPage } from '@/util/pathnameCheck'

export function OfflineModal() {
  const router = useRouter()
  const closeModal = useStore((state) => state.closeModal)
  const isOnline = useStore((state) => state.isOnline)
  const stopTimer = useSession((s) => s.stopTimer)
  const getTestProperty = useSession((s) => s.getTestProperty)
  const { resumeTimer } = useBookmarking()
  const { t } = useTranslation(['tests', 'common'])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const shouldPauseOnDisconnect = getTestProperty(
    (test) => test.shouldPauseOnDisconnect
  )

  useEffect(() => {
    if (shouldPauseOnDisconnect) {
      stopTimer()
    }
  }, [shouldPauseOnDisconnect, stopTimer, isOnline, closeModal])

  async function handleResume() {
    if (isAnInstructionsPage(router.pathname)) {
      // do not resume the timer in non-timed contexts - FE timer will be off if subsequent offline modals stop the timer
      closeModal(true)
      return
    }

    try {
      setIsSubmitting(true)
      await resumeTimer.mutateAsync()
      closeModal(true)
    } catch (error) {
      console.log(error)
      showNotification({
        type: 'error',
        message: t('errors.nonSpecificTryAgain'),
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal hideClose={true} variant='medium body-no-close'>
      <div className='modal-header error'>
        <h2 className='tc'>{t('modals.offline.title')}</h2>
      </div>
      <div className='modal-content'>
        <p className='tl'>{parse(t('modals.offline.offlineInstructions'))}</p>
        <p className='tl'>{t('modals.offline.modalCloseInstructions')}</p>
        <div className='modal-actions background-inherit'>
          <Button
            onClick={handleResume}
            isSubmitting={isSubmitting}
            disabled={!isOnline}
          >
            {t('common:continue')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
