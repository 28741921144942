import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useRouter } from 'next/router'

import { showErrorMessage } from '@/components/notification'
import { Modal } from '@/components/modals'
import { Button } from '@/components/button'
import { RespondentInformationValidationForm } from '@/components/respondent-information-validation-form/index.js'

import { useStore, useSession } from '@/util/store'
import { post } from '@/util/api'
import { apiUrl } from '@/util/urls'

export const RespondentInformationValidationModal = () => {
  const modalState = useStore((state) => state.modalState);
  const closeModal = useStore((state) => state.closeModal);
  const router = useRouter();
  const {
    respondentData,
    activeTest,
    organizationAgreements
  } = modalState
  const [isSubmitting, setIsSubmitting] = useState(false);
  const setRespondentToken = useSession((state) => state.setRespondentToken);
  const setReentryCode = useSession((state) => state.setReentryCode);
  const setActiveRespondent = useSession((state) => state.setActiveRespondent);

  const { t } = useTranslation(['tests', 'common'])

  const submitForm = async () => {
    try {
          setIsSubmitting(true);
          const { id, token, resumeCode } = await post(
            apiUrl('respondentSignup') + `/${activeTest.testCode}`,
            respondentData
          )
          await post(apiUrl('respondents') + `/${id}/accept-tos-pp`, null, {
            respondentToken: token,
          })
    
          if (organizationAgreements?.results?.length) {
            await post(
              apiUrl('organizationRespondentAgreements'),
              {
                agreement: organizationAgreements?.results[0]?.id,
              },
              { respondentToken: token }
            )
          }
    
          setRespondentToken(token)
          setReentryCode(resumeCode)
          setActiveRespondent({ respondentId: id })
          await router.replace(`/tests/respondent-assessments`);
          closeModal();
        } catch (error) {
          if (error.code === 400) {
            console.log(error.data['code'])
            if (error.data['code']) {
              showErrorMessage(t(`pages.start.${error.data['code']}`))
            } else {
              showErrorMessage(t('errors.saveAssessment400'))
            }
          }
          console.error('ERROR: ', error)
        }
        setIsSubmitting(false);
      }

  return (
    <Modal variant='medium body-no-close'>
      <div className='modal-content'>
        <div className='intro tl pt-1'>
          <h1>{t('pages.respondentInfo.verification.heading')}</h1>
        </div>
        <RespondentInformationValidationForm 
            respondentInformationObj={respondentData}/>
      </div>
      <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Back to Edit
          </button>
          <Button
            className='btn'
            onClick={submitForm}
            isSubmitting={isSubmitting}
          >
            Confirm
          </Button>
        </div>
    </Modal>
  )
}
