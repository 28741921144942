import {
  format,
  isBefore,
  isSameDay,
  startOfDay,
  differenceInMonths,
  subMonths,
} from 'date-fns'
import { enUS, enCA, fr } from 'date-fns/locale'

const localeMap = {
  'en-US': enUS,
  'en-CA': enCA,
  fr: fr,
}

const StandardDateFormat = 'yyyy-MM-dd';

// 2021-05-01
export const standardDate = (date) => {
  return format(new Date(date), StandardDateFormat)
}
// 20210501
export const standardDateDigits = (date) => {
  return format(new Date(date), 'yyyyMMdd')
}

// Sep 21, 2021
export const semanticDate = (date) => {
  return format(new Date(date), 'MMM d, y')
}

// 2021-05-01 04:20
export const standardDateTime = (date_time) => {
  return format(new Date(date_time), `${StandardDateFormat} HH:mm`)
}

// September 21, 2021
export const semanticDateFull = (date, locale) => {
  const newDate = new Date(date)
  if (locale === 'fr') {
    const day = newDate.getDate()
    const formattedDay = day === 1 ? `${day}er` : day
    return (
      `${formattedDay} ` +
      format(newDate, `MMMM yyyy`, {
        locale: localeMap[locale],
      })
    )
  }
  return format(newDate, 'MMMM d, y', { locale: localeMap[locale] })
}

export const getTimeFromDate = (date) => {
  return format(new Date(date), 'HH:mm')
}

export const getYearFromNow = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1))
}

export const hasBeen24Hours = (dateToCheck) => {
  const TWENTY_FOUR_HOURS = 60 * 60 * 1000 * 24 /* ms */

  return new Date() - dateToCheck >= TWENTY_FOUR_HOURS
}

export const isWithinLastXMonths = (dateToCheck, numMonths) => {
  const currentDate = new Date()
  const xMonthsAgo = subMonths(currentDate, numMonths)
  const dateToCheckDate = new Date(dateToCheck)

  // Check if the dateToCheck is after the date X months ago but before or equal to the current date
  return (
    isBefore(xMonthsAgo, dateToCheckDate) &&
    differenceInMonths(currentDate, dateToCheckDate) <= numMonths
  )
}

export const isToday = (dateArg) => {
  const today = new Date()
  const date = new Date(dateArg)

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

export const isDateInPast = (date) => {
  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0, 0)

  date = new Date(date)
  date.setDate(date.getDate() + 1)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0, 0)

  return date < today
}

// January 1, 2021 or January 1-2, 2021
export const formatDateRange = (start, end) => {
  const startDate = new Date(start)
  const endDate = new Date(end)

  if (startDate.toDateString() === endDate.toDateString()) {
    return format(startDate, 'MMMM d, y')
  } else {
    return format(startDate, 'MMMM d') + format(endDate, '-d, y')
  }
}

// 8:00AM - 12:00PM
export const formatTimeRange = (start, end) => {
  const startDate = new Date(start)
  const endDate = new Date(end)

  return format(startDate, 'h:mma') + format(endDate, ' - h:mma')
}

// January 1, 2021 at 8AM - 8PM or January 1, 2021 at 8AM - January 2, 2021 at 8PM
export const formatDateTimeRange = (start, end) => {
  const startDate = new Date(start)
  const endDate = new Date(end)

  const startDateFormatted = format(startDate, 'MMMM d, y')
  const startTimeFormatted = format(startDate, 'h:mma')
  const endDateFormatted = format(endDate, 'MMMM d, y')
  const endTimeFormatted = format(endDate, 'h:mma')

  if (startDate.toDateString() === endDate.toDateString()) {
    return {
      start: `${startDateFormatted} at ${startTimeFormatted}`,
      end: `${endTimeFormatted}`,
    }
  } else {
    return {
      start: `${startDateFormatted} at ${startTimeFormatted}`,
      end: `${endDateFormatted} at ${endTimeFormatted}`,
    }
  }
}

export const filterDatesBeforeNowAfterEnd = (date, getRoomValues) => {
  let { endDate } = getRoomValues()
  const now = new Date()
  const selectedDate = new Date(date)
  return (
    (!isBefore(selectedDate, now) || isSameDay(now, selectedDate)) &&
    (endDate
      ? isBefore(selectedDate, endDate) || isSameDay(selectedDate, endDate)
      : true)
  )
}

export const filterDatesBeforeStart = (date, getRoomValues) => {
  let { startDate } = getRoomValues()
  const today = startOfDay(new Date())
  if (!startDate) startDate = today
  const selectedDate = new Date(date)

  return (
    !isBefore(selectedDate, today) &&
    (isSameDay(selectedDate, startDate) || !isBefore(selectedDate, startDate))
  )
}
