import StackTrace from 'stacktrace-js'
import { post } from '@/util/api'
import { apiUrl } from '@/util/urls'

const postErrorLog = async (stackFrames, error) => {
  const stringifiedStack = stackFrames.map((sf) => sf.toString()).join('\n')
  const logEntry = {
    url: window.location.href,
    errorMessage: error.toString(),
    stackTrace: stringifiedStack,
  }

  try {
    await post(apiUrl('errorLogs'), logEntry)
  } catch (e) {
    console.log('Error posting log:', e)
  }
}

export const logError = async (error) => {
  return StackTrace.fromError(error, { offline: true })
    .then((stackFrames) => postErrorLog(stackFrames, error))
    .catch((e) => console.log(e))
}
