import { useTranslation } from 'react-i18next';
import { format, parseISO, differenceInYears } from 'date-fns';

import styles from './respondentValidation.module.scss'

export const RespondentInformationValidationForm = (({respondentInformationObj}) => {

  const { t } = useTranslation(['tests', 'common']);

  const calculateAge = (birthdate) => {
    const today = new Date();
    return differenceInYears(today, birthdate)
  }

  function CreateAgeHTMLVerificationField({ label, dateOfBirth }) {
    const birthDate = parseISO(dateOfBirth);
    let dateValue = format(birthDate, 'yyyy/MM/dd')
    return createHTMLVerificationField(
      label, 
      `${dateValue} (Age ${calculateAge(birthDate)})`)
  }

  function CreateGenderHTMLVerificationField({ value }) {
    const TRANSLATED_VALUE_MAP ={
      'f': t('common:female'),
      'm': t('common:Male')
    }
    let translated_value = TRANSLATED_VALUE_MAP[value]
    return createHTMLVerificationField(
      t('common:gender'), 
      translated_value)
  }

  function CreateNameHTMLVerificationField ({ firstName, lastName }) {
    return createHTMLVerificationField(t('common:fullName'), `${firstName} ${lastName}`)
  }

  function CreateHTMLVerificationField({ heading, value}){
    return createHTMLVerificationField( heading, value)
  }

  function createHTMLVerificationField( heading, value) {
    return (
      <div>
        <span className={styles.verificationLabel}>
          {heading}
        </span>
        <p className={styles.verificationValue}>
          {value}
        </p>
      </div>
    )
  }

  return (
    <>
      {
        respondentInformationObj.firstName && !respondentInformationObj.lastName &&
        <CreateHTMLVerificationField
        fieldKey="firstName"
        heading={t('common:firstName')}
        value={respondentInformationObj.firstName}
      />
      }
      {
        !respondentInformationObj.firstName && respondentInformationObj.lastName &&
        <CreateHTMLVerificationField
        fieldKey="lastName"
        heading={t('common:lastName')}
        value={respondentInformationObj.lastName}
      />
      }
      {
        respondentInformationObj.firstName && respondentInformationObj.lastName &&
        <CreateNameHTMLVerificationField
          fieldKey="fullname"
          firstName={respondentInformationObj.firstName}
          lastName={respondentInformationObj.lastName}
        />
      }
      {
        respondentInformationObj.generalIdNumber &&
        <CreateHTMLVerificationField
        fieldKey="generalIdNumber"
        heading={t('common:generalIdNumber')}
        value={respondentInformationObj.generalIdNumber}
      />
      }
      {
        respondentInformationObj.militaryIdNumber &&
        <CreateHTMLVerificationField
        fieldKey="militaryIdNumber"
        heading={t('common:militaryIdNumber')}
        value={respondentInformationObj.militaryIdNumber}
      />
      }
      {
        respondentInformationObj.employeeIdNumber &&
        <CreateHTMLVerificationField
        fieldKey="employeeIdNumber"
        heading={t('common:employeeIdNumber')}
        value={respondentInformationObj.employeeIdNumber}
      />
      }
      {
        respondentInformationObj.dateOfBirth &&
        <CreateAgeHTMLVerificationField
        fieldKey="dateOfBirth"
        label={t('common:dob')}
        dateOfBirth={respondentInformationObj.dateOfBirth}
      />
      }
      {
        respondentInformationObj.email &&
        <CreateHTMLVerificationField
        fieldKey="email"
        heading={t('common:email')}
        value={respondentInformationObj.email}
      />
      }
      {
        respondentInformationObj.gender &&
        <CreateGenderHTMLVerificationField
        fieldKey="gender"
        value={respondentInformationObj.gender}
      />
      }
    </>
    
  )
})


RespondentInformationValidationForm.displayName = "RespondentInformationValidationForm";
