import Link from 'next/link'
import { format, parse, isValid} from 'date-fns'

import styles from './respondent-result.module.scss'
import classnames from '@/util/classnames'

function getDateString(date) {
  const formatString = 'MM/dd';

  let finalDate = date;

  if (typeof date === 'string') {
    // Try to parse legacy string like "March 03"
    const parsed = parse(date, 'MMMM dd', new Date());
    if (isValid(parsed)) {
      finalDate = parsed;
    }
  }

  // Final formatting with fallback
  try {
    return format(finalDate, formatString);
  } catch (e) {
    console.warn('Invalid date in getDateString:', date);
    return 'Invalid date';
  }
}

export const RespondentNameResult = ({
  fullName,
  dateOfBirth,
  linkHref,
  hasReassessment,
  isFlagged,
  style,
  variant,
}) => {
  return (
    <Link href={linkHref} passHref>
      <a className={classnames([styles.link, variant])} style={{ ...style }}>
        {fullName}
        { (dateOfBirth) &&
        <span className={`${styles['link--alt-text']}`}>{'\u00A0'}({getDateString(dateOfBirth)})</span>
        }
        
        {hasReassessment && (
          <span className={classnames([styles.icon, styles.reassessment])} />
        )}
        {isFlagged && (
          <span className={classnames([styles.icon, styles.flag])} />
        )}
        <span className={classnames([styles.icon, styles['circle-arrow']])} />
      </a>
    </Link>
  )
}
